import { commonTransition } from "../../styles/mixins";
import type { SystemStyleObject } from "@chakra-ui/react";
import type { ComponentStyleConfig } from "@chakra-ui/theme";

export const headerTypographyMixin: SystemStyleObject = {
	fontWeight: "semibold",
};

const Header: ComponentStyleConfig = {
	baseStyle: {
		wrapper: {
			width: "full",
			/**
			 * position sticky would be more "correct" but a pain to work with,
			 * so we'll stick (hehe) with "fixed" and replace the space client
			 * side (see actual component):
			 */
			position: "fixed",
			top: 0,
			zIndex: "docked",
			bg: "white",
			boxShadow: "drop",
			"--header-colors-primary": "var(--chakra-colors-brand-blue)",
			"--header-colors-secondary": "white",
		},
		logoWrapper: {
			position: "relative",
			py: { base: 0, md: 2 },
			color: "var(--header-colors-primary)",
			width: { base: 40, md: 200 },
			height: { base: 14, md: 24 },
		},
		logo: {
			display: "block",
			position: "absolute",
			left: 0,
			top: 0,
			width: "full",
			height: "full",
		},
	},
	variants: {
		withTransition: {
			wrapper: {
				...commonTransition({
					trigger: false,
					from: {
						bg: "transparent",
						boxShadow: "none",
						color: "white",
						"--header-colors-primary": "white",
						"--header-colors-secondary":
							"var(--chakra-colors-brand-blue)",
					},
					to: {
						bg: "white",
						boxShadow: "drop",
						color: "brand.blue",
						"--header-colors-primary":
							"var(--chakra-colors-brand-blue)",
						"--header-colors-secondary": "white",
					},
				}),
			},
			sentinel: {
				height: "1px",
				position: "absolute",
				top: "0",
			},
			logoWrapper: {
				color: "var(--header-colors-primary, white)",
				'[data-condition="true"] &': {
					"svg path": {
						fill: "var(--header-colors-primary, white)",
					},
				},
			},
		},
	},
};

export default Header;
