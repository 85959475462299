"use client";

import { useTenClicksToStartEditorTools } from "../EditorTools/EditorTools";

export const MaintenanceSplash: React.FC<{ className?: string }> = ({
	className,
}) => {
	const handleCopyrightClick = useTenClicksToStartEditorTools();

	return (
		<button
			type="button"
			onClick={handleCopyrightClick}
			className={className}
			// eslint-disable-next-line react/jsx-no-literals
		>
			🚧
		</button>
	);
};

// 🔬 TBD: Please evaluate
