import { SystemStyleObject, keyframes } from "@chakra-ui/react";
import { figmaHelper } from "./typography";

type CommonTransitionArgs = {
	trigger: boolean;
	to?: SystemStyleObject;
	from?: SystemStyleObject;
};

export const commonTransition = ({
	trigger,
	to,
	from,
}: CommonTransitionArgs) => ({
	transitionProperty: "common",
	transitionDuration: "500ms",
	...from,

	[`[data-condition='${trigger.toString()}'] &`]: {
		...to,
	},
});

export const fadeTransition = ({ trigger, to, from }: CommonTransitionArgs) => {
	return commonTransition({
		trigger,
		from: {
			opacity: 0,
			pointerEvents: "none",
			...from,
		},
		to: {
			opacity: 1,
			pointerEvents: "unset",
			...to,
		},
	});
};

export const fillParent: SystemStyleObject = {
	width: "100%",
	height: "100%",
	position: "absolute",
	top: 0,
	right: 0,
	bottom: 0,
	left: 0,
};

export const listStyle: SystemStyleObject = {
	"&": {
		listStyleType: "none",
		paddingLeft: 0,
		marginBottom: "2em",

		li: {
			display: "flex",
			marginBottom: "1em",
			...figmaHelper({ base: 14, lg: 16 }, { base: 21, lg: 26 }),

			_before: {
				flexShrink: 0,
				content: "''",
				backgroundImage: `/**/url("/img/checkmark.svg")`,
				backgroundRepeat: "no-repeat",
				backgroundPosition: "center, center",
				width: "1.5rem",
				height: "1.5rem",
				display: "inline-flex",
				marginRight: "0.5rem",
			},
		},
	},
};

export const pulseAnimation: SystemStyleObject = {
	animation: `${keyframes`
    from {
        opacity: 1;
    }
    to {
        opacity: 0.5;
    }
`} infinite alternate 1500ms ease-in-out`,
	"@media (prefers-reduced-motion)": {
		animation: "none",
	},
};

export const floatAnimation: SystemStyleObject = {
	animation: `${keyframes`
	0% {
		transform: translateY(10%);
	}
	50% {
		transform: translateY(-10%);
	}
	100% {
		transform: translateY(10%);
	}
	`} infinite 3s ease-in-out`,
	"@media (prefers-reduced-motion)": {
		animation: "none",
	},
};

// 🔬 Skip
