"use client";

import { ChakraProvider, useBoolean } from "@chakra-ui/react";
import { useRouter } from "next/compat/router";
import React, { ReactNode, useEffect } from "react";
import { RouterIndicator } from "src/components/RouterIndicator/RouterIndicator";
import { useGTMPageView } from "src/lib/gtm";
import theme from "src/styles/theme";
import "src/styles/fonts";
import "src/styles/cssDependencies";

export const App: React.FC<{
	children: ReactNode;
}> = ({ children }) => {
	const router = useRouter();
	const trackPageView = useGTMPageView();
	const [routerIsLoading, { on: routerLoadingOn, off: routerLoadingOff }] =
		useBoolean(false);

	useEffect(() => {
		const handleRouteChangeComplete = (url: string) => {
			trackPageView(url);
			routerLoadingOff();
		};

		if (router?.isReady) {
			router.events.on("routeChangeComplete", handleRouteChangeComplete);
			router.events.on("routeChangeStart", routerLoadingOn);

			return () => {
				router.events.off(
					"routeChangeComplete",
					handleRouteChangeComplete,
				);
				router.events.off("routeChangeStart", routerLoadingOn);
			};
		}

		return undefined;
	}, [routerLoadingOff, routerLoadingOn, router, trackPageView]);

	return (
		<ChakraProvider theme={theme}>
			<RouterIndicator isLoading={routerIsLoading} />
			{children}
		</ChakraProvider>
	);
};

// 🔬 skip
